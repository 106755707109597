exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-index-jsx": () => import("./../../../src/pages/jobs/index.jsx" /* webpackChunkName: "component---src-pages-jobs-index-jsx" */),
  "component---src-pages-key-sectors-alternative-energy-jsx": () => import("./../../../src/pages/key-sectors/alternative-energy.jsx" /* webpackChunkName: "component---src-pages-key-sectors-alternative-energy-jsx" */),
  "component---src-pages-key-sectors-index-jsx": () => import("./../../../src/pages/key-sectors/index.jsx" /* webpackChunkName: "component---src-pages-key-sectors-index-jsx" */),
  "component---src-pages-key-sectors-oil-and-gas-jsx": () => import("./../../../src/pages/key-sectors/oil-and-gas.jsx" /* webpackChunkName: "component---src-pages-key-sectors-oil-and-gas-jsx" */),
  "component---src-pages-key-sectors-renewable-energy-jsx": () => import("./../../../src/pages/key-sectors/renewable-energy.jsx" /* webpackChunkName: "component---src-pages-key-sectors-renewable-energy-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-services-cv-and-resume-writing-jsx": () => import("./../../../src/pages/services/cv-and-resume-writing.jsx" /* webpackChunkName: "component---src-pages-services-cv-and-resume-writing-jsx" */),
  "component---src-pages-services-equipment-brokerage-jsx": () => import("./../../../src/pages/services/equipment-brokerage.jsx" /* webpackChunkName: "component---src-pages-services-equipment-brokerage-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-personnel-supply-jsx": () => import("./../../../src/pages/services/personnel-supply.jsx" /* webpackChunkName: "component---src-pages-services-personnel-supply-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */)
}

